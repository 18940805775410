import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import UpdateAvailabilityInstructorController, {
    Props,
} from "./MyBookingController";
import { Box, Button, Grid, Tab, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import { icDefaultAvatar, searchIcon } from "./assets";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
    marginTop: "30px",
    paddingInline: "100px",

    "@media (max-width: 960px)": {
        marginTop: "30px",
        paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
        marginTop: "30px",
        paddingInline: '30px',
    },

    "& .sideBarContainer": {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    },

    "& .container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        width: "100%",
      },    

    "& .searchInput": {
        border: 'none',
        color: "#8391A1",
        backgroundColor: "#F7F8F9",
        width: "250px",

        "@media (max-width: 500px)": {
            width: "120px",
        },

        "@media (max-width: 380px)": {
            width: "100px",
        },

        "&:focus": {
            outline: 'none',
            border: 'none',
        },
    },
    "& .MuiTab-textColorSecondary.Mui-selected": {
        color: "#2F7735",
        cursor: "pointer"
    },
    "& .MuiTab-textColorSecondary": {
        color: "#333333",
        cursor: "pointer"
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#2F7735"
    },
    "& .MuiTab-wrapper": {
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer"
    },
    "& .MuiTabs-scroller": {
        borderBottom: "1px solid #9999994D"
    },
    "& .userPhoto": {
        width: "80px",
        borderRadius: "40px",
        height: "80px",
        objectFit: "cover"
    },
    '& .approveBtn': {
        height: '32px',
        width: "80px",
        background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
        color: '#FFFFFF',
        fontSize: "12px",
        borderRadius: '4px',
        fontWeight: 600,
        textTransform: "none",


        "@media(max-width:450px)": {
            width: "68px",
            height: '28px',
        }
    },
    '& .rejectBtn': {
        border: '1px solid #FF2B2B',
        borderRadius: '4px',
        width: "80px",
        fontSize: "12px",
        height: '32px',
        color: '#FF2B2B',
        fontFamily: "Urbanist, sans-serif",
        fontWeight: 600,
        textTransform: "none",

        "@media(max-width:450px)": {
            width: "68px",
            height: '28px',
        }
    },
    "& .buttonContainer": {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    "& .itemContainer": {
        border: "1px solid #E8ECF4",
        backgroundColor: "#F7F8F9",
        borderRadius: "8px",
        paddingRight: "15px",
        display: "flex",
        justifyContent: "space-between",
        padding: 15
    }
})

const TopViewableComponent = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    "& h1": {
        fontWeight: 700,
        fontSize: "24px"
    },
})
// Customizable Area End


export default class MyBooking extends UpdateAvailabilityInstructorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const renderBtn = (booking: any) => {
            if (booking?.attributes?.status === "Approve") {
                return (
                    <>
                        <Button 
                        // style={{ opacity: this.isInTheRange(booking?.attributes?.booking?.attributes?.time) ? 1.0 : 0.3 }}
                         data-test-id='approveBtn' className="approveBtn" 
                        // disabled={!this.isInTheRange(booking?.attributes?.booking?.attributes?.time)}
                        onClick={this.onJoin(booking)}
                        >{this.state.profile?.attributes?.role === "instructor" ? "Start" : "Join"}</Button>
                        {!this.isInTheRange(booking?.attributes?.booking?.attributes?.time) && <Button data-test-id='rejectBtn' className="rejectBtn" onClick={() => this.updateBooking(booking.id, "cancel")}>Cancel</Button>}
                    </>)
            } else if (this.state.profile?.attributes?.role === "instructor") {
                return <>
                    <Button data-test-id='approveBtn' className="approveBtn" onClick={() => this.updateBooking(booking.id, "approve")}>Approve</Button>
                    <Button data-test-id='rejectBtn' className="rejectBtn" onClick={() => this.updateBooking(booking.id, "reject")}>Reject</Button>
                </>
            }
            return <Button data-test-id='rejectBtn' className="rejectBtn" onClick={() => this.updateBooking(booking.id, "reject")}>Cancel</Button>
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <HomeLayout profile={this.state.profile} navigation={this.props.navigation} onChangeProfile={this.onChangeProfile} currentPath="MyBookings">
                <PageStyle>
                    <TopViewableComponent>
                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>My Bookings</Typography>

                        <div className="sideBarContainer">
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    bgcolor: '#F7F8F9',
                                    border: "1px solid #E8ECF4",
                                    borderRadius: '6px',
                                    paddingX: '10px',
                                    paddingY: '8px',
                                }}
                            >
                                <img src={searchIcon} style={{ width: '16px', height: '18px', marginRight: '10px' }} />
                                <input
                                    className="searchInput"
                                    data-test-id={"inputSearchText"}
                                    placeholder="Search"
                                    value={this.state.searchText}
                                    onChange={(event) => this.setState({ searchText: event.target.value })}
                                />
                            </Box>
                        </div>
                    </TopViewableComponent>
                    <TabContext value={this.state.tab}>
                        <Box sx={{ borderBottom: 1 }}>
                            <TabList onChange={(_: any, newValue: any) => {
                                this.setState({ tab: newValue })
                            }}
                                textColor="secondary"
                                indicatorColor="secondary"
                            >
                                <Tab label="Upcoming" value="1" />
                                <Tab label="Past Bookings" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" style={{ paddingInline: "0px" }}>
                            <Grid container spacing={2}>
                            {this.state.upcomingBooking?.filter((booking: any) => this.state.profile.attributes.role === "instructor" ? booking?.attributes?.player_name.toLowerCase()?.includes(this.state.searchText) : booking?.attributes?.instructor_name?.toLowerCase()?.includes(this.state.searchText)) && this.state.upcomingBooking?.filter((booking: any) => booking?.attributes?.player_name.toLowerCase()?.includes(this.state.searchText) || booking?.attributes?.instructor_name?.toLowerCase()?.includes(this.state.searchText))?.length === 0 && <Typography className="container">No Booking Found!</Typography>}
                                {this.state.upcomingBooking?.filter((booking: any) => this.state.profile.attributes.role === "instructor" ? booking?.attributes?.player_name.toLowerCase()?.includes(this.state.searchText) : booking?.attributes?.instructor_name?.toLowerCase()?.includes(this.state.searchText))?.map((booking: any) => (
                                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ position: "relative" }} key={booking?.id}>
                                        <div className="itemContainer" >
                                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                                <img className="userPhoto" src={(this.state.profile?.attributes?.role === "instructor" ? booking?.attributes?.player_profile_image : booking?.attributes?.instructor_profile_image) ?? icDefaultAvatar} />
                                                <div>
                                                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "18px", fontWeight: "700", }}>{this.state.profile?.attributes?.role === "instructor" ? booking?.attributes.player_name : booking?.attributes.instructor_name}</Typography>
                                                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "12px", fontWeight: "500", color: "#8391A1" }}>{booking?.attributes.date}</Typography>
                                                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "12px", fontWeight: "500", color: "#8391A1" }}>{booking?.attributes.time}</Typography>
                                                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "12px", fontWeight: "600", color: "#333333" }}>{booking?.attributes.duration} mins</Typography>
                                                </div>
                                            </div>
                                            <div className="buttonContainer">
                                                {renderBtn(booking)}
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2" style={{ paddingInline: "0px" }}>
                            <Grid container spacing={2}>
                            {this.state.pastBooking?.filter((booking: any) => this.state.profile.attributes.role === "instructor" ? booking?.attributes?.player_name.toLowerCase()?.includes(this.state.searchText) : booking?.attributes?.instructor_name?.toLowerCase()?.includes(this.state.searchText)).length === 0 && <Typography className="container">No Booking Found!</Typography>}
                                {this.state.pastBooking?.filter((booking: any) => this.state.profile?.attributes?.role === "instructor" ? booking?.attributes?.player_name?.toLowerCase()?.includes(this.state.searchText) : booking?.attributes?.instructor_name?.toLowerCase()?.includes(this.state.searchText))?.map((booking: any) => (
                                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ position: "relative" }} key={booking?.id}>
                                        <div className="itemContainer" >
                                            <div style={{ display: "flex", gap: 20, flexDirection: "row", }}>
                                                <img className="userPhoto test" src={(this.state.profile?.attributes?.role === "instructor" ? booking?.attributes?.player_profile_image : booking?.attributes?.instructor_profile_image) ?? icDefaultAvatar} />
                                                <div>
                                                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: "700", fontSize: "18px", }}>{this.state.profile?.attributes?.role === "instructor" ? booking?.attributes.player_name : booking?.attributes.instructor_name}</Typography>
                                                    <Typography style={{ fontSize: "12px", fontWeight: "500", color: "#8391A1", fontFamily: "Urbanist,sans-serif", }}>{booking?.attributes.date}</Typography>
                                                    <Typography style={{ fontSize: "12px", fontFamily: "Urbanist,sans-serif", fontWeight: "500", color: "#8391A1" }}>{booking?.attributes.time}</Typography>
                                                    <Typography style={{ fontWeight: "600", fontFamily: "Urbanist,sans-serif", fontSize: "12px", color: "#333333" }}>{booking?.attributes.duration} mins</Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </PageStyle>
            </HomeLayout>
            // Customizable Area End
        )
    }
}
